import {
  DescriptionCards,
  DownloadAppBanner,
  Faq,
  HowToUse,
  JumbotronSection,
  MerchantJumbotron,
  PaymentProcess,
  PresentationSlider,
  ShopCategories,
  SimpleBanner,
  StepperBlock,
  Testimonials,
} from "@/components/home";
import { CMSBlock, ComponentType } from "@/shared/types";

interface PageMapProps {
  component: CMSBlock;
}

export const PageMap = (props: PageMapProps) => {
  const { component } = props;

  switch (component.__component) {
    case ComponentType.DESCRIPTION_CARDS:
      return (
        <DescriptionCards cards={component.cards} title={component.title} />
      );
    case ComponentType.FAQ:
      return <Faq items={component.items} title={component.title} />;
    case ComponentType.DOWNLOAD_APP:
      return (
        <DownloadAppBanner
          text_top={component.text_top}
          text_bottom={component.text_bottom}
          image={component.image}
          appstoreLink={component.appstoreLink}
          googlePlayLink={component.googlePlayLink}
        />
      );
    case ComponentType.JUMBOTRON:
      return (
        <JumbotronSection
          googlePlayLink={component.googlePlayLink}
          appStoreLink={component.appStoreLink}
          content={component.content}
        />
      );
    case ComponentType.STEPS_LIST:
      return (
        <HowToUse
          image={component.image}
          items={component.items}
          title={component.title}
        />
      );
    case ComponentType.PRESENTATION_CARDS:
      return <PresentationSlider items={component.items} />;
    case ComponentType.SHOP_CATEGORIES:
      return <ShopCategories title={component.title} shops={component.shops} />;
    case ComponentType.TESTIMONIALS:
      return <Testimonials title={component.title} cards={component.cards} />;
    case ComponentType.STEPPER:
      return <StepperBlock stepperItem={component.stepperItem} />;
    case ComponentType.MERCHANT_JUMBOTRON:
      return (
        <MerchantJumbotron
          titleDesktop={component.titleDesktop}
          image={component.image}
          description={component.description}
          bottomTextMobile={component.bottomTextMobile}
          buttonText={component.buttonText}
          signupButtonText={component.signupButtonText}
        />
      );
    case ComponentType.SIMPLE_BANNER:
      return <SimpleBanner title={component.title} link={component.link} />;
    case ComponentType.PAYMENT_PROCESS:
      return <PaymentProcess title={component.title} items={component.items} />;
    default:
      return null;
  }
};
